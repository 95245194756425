import React from "react"
import Layout from "../../components/Layout/layout"
import { Container } from "@material-ui/core"
import PageInfoSection from "../../components/PageInfoSection/PageInfoSection"
import ImageCard from '../../components/PageInfoSection/components/ImageCard'
import image from '../../assets/images/maintenance.jpeg'
import Squares from '../../components/Squares/Squares'
import { graphql, useStaticQuery } from 'gatsby'

const MaintenanceManagementPage = () => {
  const { maintanance } = useStaticQuery(graphql`
  query maintanance {
    maintanance: file(relativePath: {eq: "maintenance.jpeg"}) {
      childImageSharp {
        fluid {
          src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (<Layout>
    <Squares>
      <Container>
        <PageInfoSection
          title={`maintenance &`}
          blueTitle="management"
          text="Constant updates require maintenance to ensure your website is operating at full capacity. Our support staff will ensure your website is maintaining engagement and supporting peak performance at all times."
        >
          <ImageCard image={maintanance.childImageSharp.fluid} text='Maintenance and Management' />
        </PageInfoSection>
      </Container>
    </Squares>

  </Layout>)
}


export default MaintenanceManagementPage
